<template>
  <div class="rightTool" :style="{ right: postionVal + 'rem' }">
    <div class="legend">
      <div>
        <li><img src="../../../assets/legend/sj.png" /><span>事件</span></li>
        <li><img src="../../../assets/legend/xjr.png" /><span>人员</span></li>
        <li v-for="item in legendImg" :key="item.id">
          <img :src="item.img" /><span>{{ item.name }}</span>
        </li>
      </div>
    </div>
    <div class="MapChangeClass">
      <div
        v-for="(item, index) in MapArray"
        :key="index"
        class="MapItem"
        :style="{ backgroundImage: `url(${item.img})` }"
        @click="MapChangeFun(item.value)"
      ></div>
    </div>
    <div class="tool">
      <!-- <li @click="fullScreen"> -->
      <el-switch
        v-if="xfSwitch"
        v-model="adsorbSwitch"
        @change="onadsorbSwitch"
      >
      </el-switch>
      <!-- </li> -->
      <el-popover
        trigger="hover"
        placement="left-start"
        popper-class="tool layerpopo"
      >
        <div class="mapTool">
          <span
            v-for="(item, index) in tool"
            :key="index"
            :class="toolActive == item.value ? 'active' : ''"
            @click="draw(item.value, item.type)"
            ><img :src="item.img"
          /></span>
        </div>
        <li slot="reference">
          <img src="../../../assets/tool/gjx.png" /><span>工具箱</span>
        </li>
      </el-popover>
      <li @click="fullScreen">
        <img src="../../../assets/tool/qp.png" />
        <span>全屏</span>
      </li>
    </div>
  </div>
</template>

<script>
import { getDict } from "@/apis/commonType";
import { dicByType } from "@/RequestPort/maintenance";
import Cookies from "js-cookie";
export default {
  name: "Bar",
  components: {},
  props: {
    query: { type: Boolean, default: true },
    type: { type: String, default: "line" },
    layerName: {
      type: String,
      default: "overView",
    },
    layerCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 吸附开关的状态
      adsorbSwitch: true,
      // 吸附开关的显示隐藏
      xfSwitch: false,
      legendData: {},
      zoom: 14,
      Satellite: null,
      commonIcon: require("../../../assets/legend/common.png"),
      MapArray: [
        { img: require("../../../assets/legend/ysh.png"), value: "grey" },
        { img: require("../../../assets/legend/bz.png"), value: "normal" },
        { img: require("../../../assets/legend/wx.png"), value: "wx" },
        { img: require("../../../assets/legend/dql.png"), value: "blue" },
        { img: require("../../../assets/legend/jyl.png"), value: "darkblue" },
      ],
      legend: [
        {
          img: require("@/assets/lineMap/famen.png"),
          id: "721",
          name: "阀门",
        },
        {
          img: require("@/assets/lineMap/jiancedian.png"),
          id: "722",
          name: "监测点",
        },
        {
          img: require("@/assets/lineMap/santong.png"),
          id: "882",
          name: "三通",
        },
        {
          img: require("@/assets/lineMap/yonghuzhan.png"),
          id: "876",
          name: "用户站",
        },
        {
          img: require("@/assets/lineMap/bianjing.png"),
          id: "884",
          name: "变径",
        },
        {
          img: require("@/assets/lineMap/gangsu.png"),
          id: "885",
          name: "钢塑转换",
        },
        {
          img: require("@/assets/lineMap/open.png"),
          id: "878",
          name: "其他管件",
        },
        {
          img: require("@/assets/lineMap/biaoji.png"),
          id: "877",
          name: "标记",
        },
      ],
      tool: [],
      toolActive: "drag",
      FullScreenBool: false,
      legendImg: [],
      postionVal: 4.62,
      status: [],
      // 暂存数据
      newNumber:[]
    };
  },
  computed: {},
  watch: {
    "$store.getters.color"(val) {
      this.gettool(val);
    },
  },
  destroyed() {
    this.$eventBus.$off("xfSwitch");
  },
  mounted() {
    this.$eventBus.$on("xfSwitch", (data) => {
      this.adsorbSwitch = Cookies.get("adsorbSwitch") != 'false'? true :false
      this.$store.commit("changeAdsorb", this.adsorbSwitch);
      this.xfSwitch = data;
    });
    this.gettool(this.$store.getters.color);
    this.geticon();
    // this.MapChangeFun("grey");
  },
  methods: {
    /**
     * 吸附开关
     */
    onadsorbSwitch() {
      Cookies.set("adsorbSwitch", JSON.stringify(this.adsorbSwitch));
      this.$store.commit("changeAdsorb", this.adsorbSwitch);
    },
    geticon() {
      let parames = {
        parentCode: 20000,
      };
      let legend = this.legend;
      let newlegend = [];
      // getDict(parames).then((res) => {
      //   if (res.code == 200) {
      //     let dict = res.data;
      //     dict.forEach((item) => {
      //       let isHaveIcon = false;
      //       let newItemIcon = item;
      //       legend.forEach((subitem) => {
      //         if (item.code == subitem.id) {
      //           newItemIcon.img = subitem.img;
      //           isHaveIcon = true;
      //         }
      //       });
      //       if (!isHaveIcon) {
      //         newItemIcon.img = this.commonIcon;
      //       }
      //       newlegend.push(newItemIcon);
      //     });
      //     this.legendImg = newlegend;
      //   }
      // });
      dicByType({parentCode:'sblx',level:3}).then(res => {
        if(res.code == 200) {
            let dict = res.data;
            this.childrenes(dict)
            this.newNumber.forEach(item => {
              let newItemIcon = item;
              legend.forEach((subitem) => {
                if (item.id == subitem.id) {
                  newItemIcon.img = subitem.img;
                  newlegend.push(newItemIcon);
                }
              });
            })
            this.legendImg = newlegend;
        } else {
            this.$message.error(res.msg)
        }
    }) 
    },
    childrenes(val) {
      val.forEach(el => {
        if(el.children) {
          
          this.childrenes(el.children)
          let {children,...data} = el
          this.newNumber.push(data)
        } else {
          this.newNumber.push(el)
        }
        this.newNumber = [...new Set(this.newNumber)]
      });
    },  
    gettool(color) {
      if (color) {
        let tool = [
          {
            img: require("../../../assets/tool/drag.png"),
            value: "drag",
            type: "drag",
          },
          {
            img: require("../../../assets/tool/point.png"),
            value: "marker",
            type: "draw",
          },
          // {
          //   img: require("../../../assets/tool/line.png"),
          //   value: "polyline",
          //   type: "draw",
          // },
          // {
          //   img: require("../../../assets/tool/area.png"),
          //   value: "polygon",
          //   type: "draw",
          // },
          // {
          //   img: require("../../../assets/tool/circle.png"),
          //   value: "circle",
          //   type: "draw",
          // },
          // {
          //   img: require("../../../assets/tool/rectangle.png"),
          //   value: "rectangle",
          //   type: "draw",
          // },
          {
            img: require("../../../assets/tool/meter.png"),
            value: "rule",
            type: "draw",
          },
        ];
        this.tool = tool;
      } else {
        let tool = [
          {
            img: require("../../../assets/tool/dragl.png"),
            value: "drag",
            type: "drag",
          },
          {
            img: require("../../../assets/tool/pointl.png"),
            value: "marker",
            type: "draw",
          },
          // {
          //   img: require("../../../assets/tool/linel.png"),
          //   value: "polyline",
          //   type: "draw",
          // },
          // {
          //   img: require("../../../assets/tool/areal.png"),
          //   value: "polygon",
          //   type: "draw",
          // },
          // {
          //   img: require("../../../assets/tool/circlel.png"),
          //   value: "circle",
          //   type: "draw",
          // },
          // {
          //   img: require("../../../assets/tool/rectanglel.png"),
          //   value: "rectangle",
          //   type: "draw",
          // },
          {
            img: require("../../../assets/tool/meterl.png"),
            value: "rule",
            type: "draw",
          },
        ];
        this.tool = tool;
      }
    },
    closeTool() {
      this.$emit("closeDraw");
      this.$parent.drawState = false;
    },
    draw(value, type) {
      if (this.$parent.pLoaction) {
        this.$parent.$parent.closePDraw();
      }

      this.closeTool();
      this.$parent.closeDraw();
      if (type == "draw") {
        if (this.toolActive == value) {
          this.toolActive = "";
        } else {
          this.$emit("draw", value, true);
          this.toolActive = value;
          this.$parent.drawState = true;
        }
      } else if (type == "drag") {
        if (this.toolActive == value) {
          this.toolActive = "";
        } else {
          this.toolActive = value;
        }
      }
    },
    /**
     * 切换背景
     */
    MapChangeFun(value) {
      let mapStyle;
      if (value) {
        localStorage.setItem("mapStyle", value);
        mapStyle = value;
      } else {
        mapStyle = localStorage.getItem("mapStyle");
      }
      if (mapStyle == "wx") {
        if (this.Satellite) {
          this.$parent.map.remove(this.Satellite);
        }
        this.Satellite = new AMap.TileLayer.Satellite();
        this.$parent.map.add(this.Satellite);
      } else {
        if (this.Satellite) {
          this.$parent.map.remove(this.Satellite);
        }
        let styleName = "amap://styles/" + mapStyle;
        setTimeout(() => {
          this.$parent.map.setMapStyle(styleName);
        }, 100);
      }
      if (mapStyle == "normal") {
        document.body.classList.remove("black");
        this.$store.commit("colorChange", "");
      } else {
        document.body.classList.add("black");
        this.$store.commit("colorChange", "black");
      }
    },
    fullScreen() {
      let fullarea = document.getElementById("mapRightId");
      let mapDiv = document.getElementById("amap");

      if (this.FullScreenBool) {
        // 退出全屏

        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        mapDiv.style.height = "auto";
        mapDiv.style.height = "calc(100vh - 120px)";
      } else {
        // 进入全屏

        if (fullarea.requestFullscreen) {
          fullarea.requestFullscreen();
        } else if (fullarea.webkitRequestFullScreen) {
          fullarea.webkitRequestFullScreen();
        } else if (fullarea.mozRequestFullScreen) {
          fullarea.mozRequestFullScreen();
        } else if (fullarea.msRequestFullscreen) {
          // IE11

          fullarea.msRequestFullscreen();
        }
        mapDiv.style.height = "calc(100vh)";
      }

      // if (this.FullScreenBool) {
      //   var el = document.documentElement
      //   var rfs =
      //     el.requestFullScreen ||
      //     el.webkitRequestFullScreen ||
      //     el.mozRequestFullScreen ||
      //     el.msRequestFullScreen
      //   if (rfs) {
      //     rfs.call(el)
      //   } else if (typeof window.ActiveXObject !== 'undefined') {
      //     var wscript = new ActiveXObject('WScript.Shell')
      //     if (wscript != null) {
      //       wscript.SendKeys('{F11}')
      //     }
      //   }
      // } else {
      //   var el = document
      //   var cfs =
      //     el.cancelFullScreen ||
      //     el.webkitCancelFullScreen ||
      //     el.mozCancelFullScreen ||
      //     el.exitFullScreen
      //   if (cfs) {
      //     cfs.call(el)
      //   } else if (typeof window.ActiveXObject !== 'undefined') {
      //     var wscript = new ActiveXObject('WScript.Shell')
      //     if (wscript != null) {
      //       wscript.SendKeys('{F11}')
      //     }
      //   }
      // }
      this.FullScreenBool = !this.FullScreenBool;
    },
  },
};
</script>

<style lang="less" scoped>
.lbor {
  border-top: 1px solid var(--background-full-skin);
  margin: 5px;
  padding-left: 5px;
}
.statusBox {
  margin: 2px;
}
.iconfont {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 0.12rem;
  margin-right: 0.08rem;
  background: #ccc;
}
.rightTool {
  position: absolute;
  bottom: 0;
  height: calc(100vh - 0.9rem);

  transition: right 0.5s;
  z-index: 2;
}
.legend {
  min-width: 1.27rem;
  position: absolute;
  bottom: 1.28rem;
  right: 0.11rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  background: var(--background-color-skin);
  color: var(--font-color-skin);
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.14rem;

    margin-top: 0.05rem;
    white-space: nowrap;
    img {
      margin-left: 0.12rem;
      margin-right: 0.08rem;
      width: 0.2rem;
      height: auto;
    }
  }
  .show {
    color: #ffffff;
  }
}
.MapChangeClass {
  width: 0.9rem;
  height: 0.73rem;
  position: absolute;
  bottom: 0.32rem;
  right: 0.11rem;
  transition: 1s width;
  overflow: hidden;
  background: var(--background-color-skin);
  .MapItem {
    width: 0.82rem;
    height: 0.65rem;
    float: right;
    margin-top: 0.04rem;
    color: white;
    margin-right: 0.04rem;
    cursor: pointer;
    background-size: 100% 100%;
  }
  .MapItem:hover {
    box-shadow: 0px 0px 10px #888888;
  }
}
.MapChangeClass:hover {
  width: 3.5rem;
}
.tool {
  position: absolute;
  top: 0.16rem;
  right: 0.11rem;
  font-size: 0.15rem;
  li {
    background-size: 100% 100%;
    width: 0.67rem;
    height: 0.67rem;
    background-size: 100% 100%;
    margin-top: 0.11rem;
    cursor: pointer;
    overflow: hidden;
    img {
      width: 0.31rem;
      height: auto;
      display: block;
      margin: 0 auto;
      margin-top: 0.08rem;
      margin-bottom: 0.02rem;
    }
    span {
      width: 100%;
      display: block;
      line-height: 1;
      text-align: center;
      color: var(--font-b-color-skin);
    }
  }
  // li:hover {
  //   background: url('../../../assets/tool/bghover.png') no-repeat;
  //   background-size: 100% 100%;
  // }
}
// 吸附开关
.tool {
  /deep/.el-switch__core {
    width: 52px !important;
  }
  /deep/.el-switch__core::before {
    content: "吸附";
    position: absolute;
    top: 0;
    left: 20px;
    color: #2c9ef7;
  }
  .is-checked /deep/.el-switch__core::before {
    content: "吸附";
    position: absolute;
    top: 0;
    left: 5px;
    color: #ffffff;
  }
}
.mapTool {
  margin: 2px;
  background: var(--background-full-skin);
  span {
    display: block;
    padding: 2px 10px;
    text-align: center;
    cursor: pointer;
    background: var(--background-full-skin);
    img {
      width: 30px;
      height: 30px;
      margin: 0 auto;
    }
  }
  span.active {
    background: var(--background-c-color-skin);
  }
}
/deep/ .el-switch__label--left {
  width: 30px;
}
</style>
